import axios, { AxiosResponse } from 'axios'

const getLatestInformation = (community_id: string): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/dashboard/latest_informations`)

const getActiveUsers = (community_id: string, params: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/dashboard/active_users`, { params })

const getActions = (community_id: string, params: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/dashboard/actions`, { params })

const getGroups = (community_id: string, params: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/dashboard/groups`, { params })

const getPostsGraph = (community_id: string, params: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/dashboard/posts_graph`, { params })

const getPosts = (community_id: string, params: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/dashboard/posts`, { params })

const getInvitationRoutes = (community_id: string, params: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/dashboard/invitation_routes`, { params })

const communityDashboardService = {
  getLatestInformation,
  getActiveUsers,
  getActions,
  getGroups,
  getPostsGraph,
  getPosts,
  getInvitationRoutes,
}

export default communityDashboardService
